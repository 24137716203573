import $ from "jquery";
import moment from "moment";
import { func } from "prop-types";

export const on_load = () => {
  I18n.locale = $("html").attr("lang");
  initPopover();
  initTooltip();
  initDatepicker();
  initDateTimepicker();
  initTimepicker();
  initLoader();
  initScroll();
};

export const initPopover = () => {
  $('[data-toggle="popover"]').popover();
};

const initTooltip = () => {
  $('[data-toggle="tooltip"]').tooltip();
};

export const initLoader = () => {
  $(document).on("ajaxStart", function () {
    $("#loader_gif").show();
  });
  $(document).on("ajaxStop", function () {
    $("#loader_gif").hide();
  });

  document.addEventListener("DOMContentLoaded", function () {
    setTimeout(function () {
      if ($("#loader_gif").is(":visible")) {
        $("#loader_gif").hide();
      }
    }, 1000);
  });

  window.onunload = function () {
    console.log("unload");
    $("#loader_gif").hide();
  };

  $(
    ".loader_on_click, li.pagination__item, ul.navbar-nav.main-navbar > li.nav-item:not(.multiple) > a.nav-link, ul.nav__subnav > li > a.nav__sublink"
  ).click(() => {
    $("#loader_gif").show();
  });
};

export const initSelect2 = () => {
  $(".select2").select2({
    placeholder: I18n.t("js.choose"),
    allowClear: true,
    language: I18n.locale
  });
};

export const initDateRangePicker = () => {
  $(".daterange").daterangepicker({
    autoUpdateInput: false,
    showDropdowns: true,
    opens: "center",
    minDate: `${moment()
      .add(1, "days")
      .format("DD.MM.YYYY")}`,
    maxDate: `${moment()
      .add(1, "year")
      .endOf("year")
      .format("DD.MM.YYYY")}`,
    locale: {
      format: "DD.MM.YYYY",
      applyLabel: I18n.t("js.filter.choose"),
      cancelLabel: I18n.t("js.filter.cancel"),
      daysOfWeek: I18n.t("js.days_of_week"),
      monthNames: I18n.t("js.month_names"),
      fromLabel: "test",
      firstDay: 1
    }
  });

  // show labels for both tables
  $(".daterange").on("show.daterangepicker", function() {
    $(".drp-calendar.left .table-condensed thead").prepend(
      "<tr><th colspan='99'>" +
        I18n.t("js.filter.terms_date_start_gt") +
        "</th></tr>"
    );
    $(".drp-calendar.right .table-condensed thead").prepend(
      "<tr><th colspan='99'>" +
        I18n.t("js.filter.terms_date_end_lt") +
        "</th></tr>"
    );
  });

  $(".daterange").on("cancel.daterangepicker", function(ev, picker) {
    $(".daterange").val("");
    $("#q_date_start_gteq").val("");
    $("#q_date_start_lteq")
      .val("")
      .trigger("change");
  });

  $(".daterange").on("apply.daterangepicker", function(ev, picker) {
    $(this).val(
      picker.startDate.format("DD.MM.YYYY") +
        " - " +
        picker.endDate.format("DD.MM.YYYY")
    );
    $("#q_date_start_gteq").val(picker.startDate.format("DD.MM.YYYY"));
    $("#q_date_start_lteq")
      .val(picker.endDate.format("DD.MM.YYYY"))
      .trigger("change");
  });
};

const initDatepicker = () => {
  $(".datepicker").datetimepicker({
    icons: {
      time: "fas fa-clock",
      date: "fas fa-calendar-alt",
      up: "fas fa-arrow-up",
      down: "fas fa-arrow-down",
      previous: "fas fa-arrow-left",
      next: "fas fa-arrow-right",
      today: "fas fa-calendar-check",
      clear: "fas fa-trash-alt",
      close: "fas fa-times",
    },
    maxDate: $(".datepicker").data("maxdate")
      ? moment($(".datepicker").data("maxdate"))
      : moment().add(1, "year").endOf("year"),
    locale: I18n.locale,
    useCurrent: false,
    format: "DD.MM.YYYY",
  });
};

export const initDatePickerWith = (wrapper, minDate) => {
  return $(wrapper).datetimepicker({
    icons: {
      time: "fas fa-clock",
      date: "fas fa-calendar-alt",
      up: "fas fa-arrow-up",
      down: "fas fa-arrow-down",
      previous: "fas fa-arrow-left",
      next: "fas fa-arrow-right",
      today: "fas fa-calendar-check",
      clear: "fas fa-trash-alt",
      close: "fas fa-times"
    },
    locale: I18n.locale,
    useCurrent: false,
    format: "DD.MM.YYYY",
    minDate: minDate
  });
};

const initDateTimepicker = () => {
  $(".datetimepicker").datetimepicker({
    icons: {
      time: "fas fa-clock",
      date: "fas fa-calendar-alt",
      up: "fas fa-arrow-up",
      down: "fas fa-arrow-down",
      previous: "fas fa-arrow-left",
      next: "fas fa-arrow-right",
      today: "fas fa-calendar-check",
      clear: "fas fa-trash-alt",
      close: "fas fa-times"
    },
    locale: I18n.locale,
    stepping: 5,
    collapse: false,
    sideBySide: true,
    useCurrent: false,
    format: "DD.MM.YYYY HH:mm"
  });
};

const initTimepicker = () => {
  $(".timepicker").datetimepicker({
    icons: {
      time: "fas fa-clock-o",
      date: "fas fa-calendar",
      up: "fas fa-arrow-up",
      down: "fas fa-arrow-down",
      previous: "fa fa-arrow-left",
      next: "fas fa-arrow-right",
      today: "fas fa-calendar-check-o",
      clear: "fas fa-trash-o",
      close: "fas fa-times"
    },
    locale: I18n.locale,
    useCurrent: false,
    format: "HH:mm",
    stepping: 5
  });
};

const initScroll = () => {
  $(document).on("click", "a.animated-scroll", function(event) {
    const anchor = $(this).attr("href");
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $(anchor).offset().top
        },
        1000
      );
    event.preventDefault();
  });
};
