const shouldImport = (req, controller_name, action_name) => {
  return action_name
    ? req.controller_name === controller_name && req.action_name === action_name
    : req.controller_name === controller_name;
};

export const requestDetail = JSON.parse(atob(requestDetailHash));

export const importScriptsByPage = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const req = requestDetail;
    if (!req) return;

    if (req.env == "development") {
      console.log(req);
    }

    if (shouldImport(req, "admin/versions", "show")) {
      import("../src/js/diff");
    }

    if (shouldImport(req, "home", "index")) {
      import("../src/js/page_popup");
    }
  });
};
