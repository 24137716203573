import Swiper from "swiper/dist/js/swiper.min";
import { FlipClock } from "../src/plugins/flipClock/js/flipclock.min.js";

$(document).ready(function () {
  I18n.locale = $("html").attr("lang");

  $(function () {
    $(document).on("click", "a.page-scroll", function (event) {
      var $navbarHeight = $("nav.fixed-top").outerHeight();
      var $anchor = $(this).attr("href");
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $($anchor).offset().top - $navbarHeight,
          },
          1500
        );
      event.preventDefault();
    });
  });

  $(function () {
    if ($(window).width() >= 768) {
      $(window).scroll(function () {
        if ($(window).scrollTop() > 1) {
          $(".navbar-brand img").addClass("smaller");
        } else {
          $(".navbar-brand img").removeClass("smaller");
        }
      });
    }
  });

  // Gallery show more
  $(".gallery--wrap .btn-more").on("click", function (event) {
    $(".gallery--wrap").toggleClass("JS-selected");
    $(this).toggleClass("JS-clicked");

    var text = $(".btn-more--wrap span").text();
    $(".btn-more--wrap span").text(
      text == I18n.t("js.more_images")
        ? I18n.t("js.less_images")
        : I18n.t("js.more_images")
    );
  });
  $(".gallery-wrap .gallery").animate('{height:"toggle"}');

  //MORE PARAMS
  $("#more-params").hide();
  $("#JS-more-params").on("click", function (event) {
    $(this).toggleClass("clicked");
    $("#more-params").slideToggle();
    var el = $("#JS-more-params span");
    el.text(
      el.text() == I18n.t("js.more_params")
        ? I18n.t("js.less_params")
        : I18n.t("js.more_params")
    );
  });

  //CALCULATION BOX
  $(".JS-item-edit").hide();

  $(".JS-item").on("click", function (event) {
    $(".JS-item-edit").slideToggle("slow");
    $(this).toggleClass("active");
  });

  // Toggle Order Form
  $("#form-order").hide();

  $("#JS-order-btn").on("click", function (event) {
    $("#form-order").show("slow");
    $(this).hide();
  });

  // SWIPER
  var swiper = new Swiper(".swiper-container--full", {
    slidesPerView: 1,
    spaceBetween: 30,
    lazy: true,
    loop: true,
    effect: "fade",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
    },
  });

  // SWIPER CAROUSEL
  var swiperCarousel = new Swiper(".swiper-container--carousel", {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
  });

  //TOOLTIPS
  $('[data-toggle="tooltip"]').tooltip();

  //SMOOTH SCROLL
  // Select all links with hashes
  $('.detail-content a[href^="#"]').on("click", function (e) {
    // e.preventDefault();

    var target = this.hash,
      $target = $(target);
    $(this).addClass("active");

    const headerHeight = $("header").height();
    const navHeight = $(".nav--wrap").height();

    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $target.offset().top - (headerHeight + navHeight),
        },
        900,
        "swing",
        function () {
          window.location.hash = target;
        }
      );
  });

  //SELECT2
  $(".JS-select2-multiple").select2();
  $(".JS-example-basic-single").select2();

  //FLIPCLOCK
  var clock = $(".happyHour-clock").FlipClock({
    clockFace: "TwentyFourHourClock",
  });

  //DROPDOWN
  $(".dropdown-toggle").dropdown();

  var formHeight = $(".order-form").height();
  var totalBlockHeight = $(".order-form__block--total").height();
  var contentFormHeight = formHeight - totalBlockHeight;
  var previousScroll = 0;

  $(window).scroll(function () {
    var currentScroll = $(this).scrollTop();
    if (currentScroll > contentFormHeight) {
      $(".order-form__block--total--xs").addClass("JS-hide");
    } else {
      $(".order-form__block--total--xs").removeClass("JS-hide");
    }
    previousScroll = currentScroll;
  });
});

$(window).scroll(function () {
  var scroll = $(window).scrollTop();

  if (scroll >= 100) {
    $("body").addClass("JS-scrolled");
  } else {
    $("body").removeClass("JS-scrolled");
  }

  const scrollDistance = $(window).scrollTop();
  const headerHeight = $("header").height();
  const navHeight = $(".nav--wrap").height();

  // Assign active class to nav links while scolling
  $(".JS-page-detail-section").each(function (i) {
    let top = $(this).offset().top - (headerHeight + navHeight + 50);

    if (scrollDistance >= top) {
      $(".detail-content .nav a.active").removeClass("active");
      $(".detail-content .nav a").eq(i).addClass("active");
    }
  });
});
