//require.context('../images', true);
import "../images/call-us.jpg";
import "../images/logo.svg";

//css
import "swiper/dist/css/swiper.min.css";
import "select2/dist/css/select2.min.css";

// js
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.min";
import select2 from "select2/dist/js/select2.full.min";
import "select2/dist/js/i18n/cs";
import "../src/plugins/bootstrap-4.3.1/dist/js/bootstrap.min";
import moment from "moment/src/moment";
import "../src/plugins/datepicker/js/bootstrap-datetimepicker.min";
import "../src/plugins/datepicker/css/bootstrap-datetimepicker.min.css";
import "daterangepicker/daterangepicker.js";
import "daterangepicker/daterangepicker.css";
import "lightbox2/dist/css/lightbox.min.css";
import "lightbox2/dist/js/lightbox.min";

import "../src/application.scss";
import "./theme.js";
import ClipboardJS from "../src/plugins/clipboard.min";

import Rails from "@rails/ujs";
import {
  initSelect2,
  on_load,
  initDatePickerWith,
  initDateRangePicker
} from "./libs";
import "./translations.js";
import { importScriptsByPage } from "../helpers/dynamic_imports";

window.jQuery = $;
window.$ = $;
Rails.start();
window.Rails = Rails;
window.on_load = on_load;
window.initSelect2 = initSelect2;
window.ClipboardJS = ClipboardJS;
window.initDateRangePicker = initDateRangePicker;
window.I18n = I18n;
window.initDatePickerWith = initDatePickerWith;

// on document ready
$(document).ready(function () {
  on_load();
  moment.locale(I18n.locale);
  initSelect2(); //removed from onload because loaded select2 would be overwritten on on_load call
});

importScriptsByPage();

window.initClipboard = function () {
  return $(".copyable").click(function (e) {
    if (window.navigator && window.navigator.clipboard) {
      return navigator.clipboard.writeText($(e.target).data().clipboardText);
    }
  });
};

(function () {
  window.initClipboard();
}).call(this);

